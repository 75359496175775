import { Stack } from 'component-library';
import { useTranslation } from 'react-i18next';

import { LandMonitoringPageWrapper } from '../../components/LandMonitoring.page-wrapper';
import { CanopyCover } from './components/canopyCover/CanopyCover';
import { CanopyHeight } from './components/canopyHeight/CanopyHeight';

export const ForestStructureDetailPage = () => {
  const { t } = useTranslation();

  return (
    <LandMonitoringPageWrapper title={t('shared.ncaDetail.details.forest.labels.forestStructure')}>
      <Stack spacing={8}>
        <ForestStructure />
        <ForestStructurePlotList />
      </Stack>
    </LandMonitoringPageWrapper>
  );
};

export const ForestStructure = () => {
  return (
    <>
      <CanopyHeight />
      <CanopyCover />
    </>
  );
};

export const ForestStructurePlotList = () => {
  return (
    <>
      <div>{/* TODO: MVP-4743 add plot list */}</div>
    </>
  );
};
