import { useTranslation } from 'react-i18next';

import { NCAReportBalanceType, NCAReportBalanceValueType } from '@/api/rest/resources/types/ncaBalance';
import { NCCardSectionHeader } from '@/components/NCCard/NCCard';
import { useNCABalance, useNCAReportBalance } from '@/pages/shared/hooks/useNcaReportByProjectId';
import { formatBalanceKilogramsToTonnes } from '@/utils/formatting/formatKgToTon';

import { NCABalanceTile } from './NCABalanceTile';

export const AboveGroundCarbonBalance = () => {
  const { t } = useTranslation();

  const aboveGroundCarbonBalance = useNCAReportBalance(NCAReportBalanceType.ABOVE_GROUND_CARBON);

  const openingValue = formatBalanceKilogramsToTonnes(
    useNCABalance(aboveGroundCarbonBalance, NCAReportBalanceValueType.opening_value),
  );
  const latestValue = formatBalanceKilogramsToTonnes(
    useNCABalance(aboveGroundCarbonBalance, NCAReportBalanceValueType.latest_value),
  );
  const latestValueTrend = useNCABalance(aboveGroundCarbonBalance, NCAReportBalanceValueType.one_year_change_perc);
  const oneYearChange = formatBalanceKilogramsToTonnes(
    useNCABalance(aboveGroundCarbonBalance, NCAReportBalanceValueType.one_year_change_value),
  );

  return (
    <div data-testid='above-ground-carbon-stats-tile' className='flex w-full flex-col gap-6'>
      <NCCardSectionHeader
        title={t('shared.ncaDetail.details.carbon.labels.aboveTheGroundCarbonStorageData')}
        popover={{
          title: t('shared.ncaDetail.details.carbon.explainers.aboveTheGroundCarbonStorage.title'),
          body: t('shared.ncaDetail.details.carbon.explainers.aboveTheGroundCarbonStorage.body'),
        }}
      />
      <NCABalanceTile
        openingValue={openingValue}
        latestValue={latestValue}
        latestValueTrend={latestValueTrend}
        oneYearChange={oneYearChange}
      />
    </div>
  );
};
