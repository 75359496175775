import { ColumnDef } from '@tanstack/react-table';
import { Loader, Stack } from 'component-library';
import { useTranslation } from 'react-i18next';

import { PlotType } from '@/api/rest/resources/types/plot';
import { SuspenseBoundary } from '@/components';
import { CarbonExplainer } from '@/components/NCExplainers/CarbonExplainer';
import { useScreenSize } from '@/hooks/useScreenSize';
import { useProjectDetailById } from '@/pages/shared/hooks/useProjectDetailById';

import { LandMonitoringPageWrapper } from '../../components/LandMonitoring.page-wrapper';
import { CarbonStorageBgCell, PlotRow } from '../../components/plot-list/Cells';
import { PlotListDesktop } from '../../components/plot-list/PlotListDesktop';
import { PlotListTitle } from '../../components/plot-list/PlotListTitle';
import { ToggleSortButton } from '../../components/plot-list/ToggleSortButton';
import { AboveGroundCarbonDetailPage } from '../aboveGroundCarbon/AboveGroundCarbonDetail.page';
import { CarbonCard } from './components/nc-breakdown/Card';

export const CarbonDetailPage = () => {
  const project = useProjectDetailById().data;

  // TODO: MVP-4743 dont index yuck
  const projectLandType = project.landtypes_allowed[0];

  // TODO: MVP-4724 swap out with PlotType.FORESTRY
  return projectLandType === PlotType.TREE_COVER ? <AboveGroundCarbon /> : <BelowGroundCarbon />;
};

const AboveGroundCarbon = () => {
  // TODO: MVP-4726
  return <AboveGroundCarbonDetailPage />;
};

const BelowGroundCarbon = () => {
  const { t } = useTranslation();
  const isSmallScreen = useScreenSize() === 'small';

  const customPlotListColumns: ColumnDef<PlotRow>[] = [
    {
      id: 'carbonStorageBg',
      accessorKey: 'carbonStorageBg',
      header: ({ column }) => (
        <ToggleSortButton column={column} className='-ml-2.5'>
          {t('global.analysis.carbonStorageBg')}
        </ToggleSortButton>
      ),
      cell: CarbonStorageBgCell,
    },
  ];

  return (
    <LandMonitoringPageWrapper title={t('shared.ncaDetail.details.carbon.labels.carbon')}>
      <Stack spacing={8}>
        <CarbonCard />
        <SuspenseBoundary
          fallback={
            <Stack center className='h-[600px]'>
              <Loader />
            </Stack>
          }
        >
          <div>
            <PlotListTitle
              title={t('shared.ncaDetail.details.carbon.labels.carbonDataPerPlot')}
              action={!isSmallScreen ? <CarbonExplainer /> : null}
            />
            <PlotListDesktop customColumns={customPlotListColumns} />
          </div>
        </SuspenseBoundary>
      </Stack>
    </LandMonitoringPageWrapper>
  );
};
